import { routes } from './routes.js';
import locations from './locations/Localizacoes.tsx';
import Carregador from './locations/Carregador.tsx';
import Sessoes from './locations/Sessoes.tsx';
import { react2angular } from 'react2angular';

export default angular.module('app.locations', [])
    .config(routes)
    .component("locationsList2", react2angular(locations, [], []))
    .component("chargerList2", react2angular(Carregador, [], []))
    .component("sessionList2", react2angular(Sessoes, [], []))
    .name;
