import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, } from "material-react-table";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import moment from "moment";
function TarifarioLista({ $state }) {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });
    const [columnSort, setColumnSort] = useState([]);
    const [data, setData] = useState({
        data: [],
        meta: { currentPage: 0, itemsPerPage: 20, totalItems: 0, totalPages: 0 },
        links: {},
    });
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [modalError, setModalError] = useState({
        show: false,
        title: "",
        body: "",
    });
    const initialFetch = async () => {
        if (loading) {
            setLoading(false);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            let sorter = [];
            columnSort.forEach((r) => {
                sorter.push(`${r.id}:${r.desc ? "DESC" : "ASC"}`);
            });
            const response = await axios.get(`https://dev-hgp-ocpi.streamline.pt/hgp/tariffs/table?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}&sortBy=${sorter}`, {
                headers: {
                    Authorization: "Token 2d83dd7c-ad5e-475f-ad7f-c4b51ce06d57",
                },
            });
            // Update URL
            setData(response.data);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, columnSort]);
    let roundToNDigits = (number, p) => (Math.round((number + Number.EPSILON) * Math.pow(10, p)) / Math.pow(10, p)).toLocaleString(undefined, { minimumFractionDigits: 3 });
    const columns = useMemo(() => [
        {
            accessorKey: "party_id",
            header: "Designação OPC",
            size: 50,
        },
        {
            accessorKey: "type",
            header: "Tipo de Tarifário",
            size: 50,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value)
                    return `${value}`;
                return "-";
            },
        },
        {
            accessorKey: "start_date_time",
            header: "Data de Inicio",
            size: 50,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value != undefined)
                    return moment(value).format("DD-MM-YYYY");
                return "-";
            },
        },
        {
            muiTableHeadCellProps: {
                align: "right",
            },
            muiTableBodyCellProps: {
                align: "right",
            },
            enableSorting: false,
            accessorFn: (row) => "energy",
            header: "€ / kWh",
            size: 25,
            Cell: ({ row }) => {
                let valor = 0;
                row.original.elements.forEach((r) => {
                    if (r.price_components[0].type === "ENERGY") {
                        valor = r.price_components[0].price;
                    }
                });
                return `${roundToNDigits(valor, 3)} €`;
            },
        },
        {
            muiTableHeadCellProps: {
                align: "right",
            },
            muiTableBodyCellProps: {
                align: "right",
            },
            enableSorting: false,
            accessorFn: (row) => "min",
            header: "€ / min",
            size: 25,
            Cell: ({ row }) => {
                let valor = 0;
                row.original.elements.forEach((r) => {
                    if (r.price_components[0].type === "TIME") {
                        valor = r.price_components[0].price;
                    }
                });
                return `${roundToNDigits(valor, 3)} €`;
            },
        },
        {
            muiTableHeadCellProps: {
                align: "right",
            },
            muiTableBodyCellProps: {
                align: "right",
            },
            enableSorting: false,
            accessorFn: (row) => "flat",
            header: "€ / carregamento",
            size: 25,
            Cell: ({ row }) => {
                let valor = 0;
                row.original.elements.forEach((r) => {
                    if (r.price_components[0].type === "FLAT") {
                        valor = r.price_components[0].price;
                    }
                });
                return `${roundToNDigits(valor, 3)} €`;
            },
        },
        {
            muiTableHeadCellProps: {
                align: "right",
            },
            muiTableBodyCellProps: {
                align: "right",
            },
            enableSorting: false,
            accessorFn: (row) => "parking_time",
            header: "€ / ocupação",
            size: 25,
            Cell: ({ row }) => {
                let valor = 0;
                row.original.elements.forEach((r) => {
                    if (r.price_components[0].type === "PARKING_TIME") {
                        valor = r.price_components[0].price;
                    }
                });
                return `${roundToNDigits(valor, 3)} €`;
            },
        },
        {
            accessorKey: "last_updated",
            header: "Ultima Atualização",
            size: 50,
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value != undefined)
                    return moment(value).format("DD-MM-YYYY [às] HH:mm");
                return "-";
            },
        },
    ], []);
    const goto = (country_code) => {
        $state.go("app.locations.session", { country_code });
    };
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = "Não há registos a serem exibidos";
    return (<Container maxWidth={false}>
      <h1>Tarifário de Venda (OPC)</h1>
      <MaterialReactTable columns={columns} data={data.data} rowCount={data.meta.totalItems} pageCount={data.meta.totalPages} state={{
            density: "compact",
            isLoading: loading,
            pagination: {
                pageIndex: data?.meta.currentPage > 0 ? data?.meta.currentPage - 1 : 0,
                pageSize: data?.meta.itemsPerPage,
            },
            sorting: columnSort.map((sort) => ({ id: sort.id, desc: sort.desc })),
        }} localization={localization} muiCircularProgressProps={{
            color: "secondary",
            thickness: 5,
            size: 55,
        }} enableTopToolbar={false} manualPagination={true} onPaginationChange={setPagination} manualSorting={true} onSortingChange={setColumnSort} layoutMode="grid" muiToolbarAlertBannerProps={modalError.show
            ? {
                color: "error",
                children: (<div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {modalError.title}:
                    </Typography>
                    <Typography>{modalError.body}</Typography>
                  </div>),
            }
            : undefined}/>
    </Container>);
}
export default TarifarioLista;
