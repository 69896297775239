import { Card, Chip, Container, Grid, Tooltip, Typography, } from "@mui/material";
import { MaterialReactTable, } from "material-react-table";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Icon as MdiIcon } from "@mdi/react";
import { mdiBroadcast, mdiCellphone, mdiEvPlugCcs2, mdiEvPlugChademo, mdiEvPlugType2, } from "@mdi/js";
import "leaflet/dist/leaflet.css";
function Charger({ $state }) {
    const [data, setData] = useState();
    const { id, party_id, country_code } = $state.params;
    const [isLoading, setIsLoading] = useState(true);
    const [evses, setEvses] = useState([]);
    const [modalError, setModalError] = useState({
        show: false,
        title: "",
        body: "",
    });
    async function GetCarregadores() {
        let response;
        try {
            response = await axios.get(`https://dev-hgp-ocpi.streamline.pt/hgp/locations/direct/${country_code}/${party_id}/${id}`, {
                headers: {
                    Authorization: "Token 2d83dd7c-ad5e-475f-ad7f-c4b51ce06d57",
                },
            });
        }
        catch (error) {
            setIsLoading(false);
            setModalError({
                show: true,
                title: error.response.data.error,
                body: error.response.data.message,
            });
            throw error;
        }
        if (response.data.coordinates.latitude != null) {
            response.data.coordinates.latitude = parseFloat(response.data.coordinates.latitude);
        }
        if (response.data.coordinates.longitude != null) {
            response.data.coordinates.longitude = parseFloat(response.data.coordinates.longitude);
        }
        return response.data;
    }
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            const data = await GetCarregadores();
            setData(data);
            setEvses(data.evses ?? []);
            setIsLoading(false);
            //console.log(data.evses);
        };
        fetchData();
    }, []);
    const columns = useMemo(() => [
        {
            accessorKey: "uid",
            header: "UID",
            size: 50,
        },
        {
            accessorKey: "status",
            header: "Estado",
            size: 50,
        },
        {
            accessorKey: "last_updated",
            header: "Última Atualização",
            size: 50,
            Cell: ({ renderedCellValue }) => moment(renderedCellValue?.toString()).format("DD-MM-YYYY [às] HH:mm"),
        },
        {
            accessorFn: (row) => {
                //console.log(row);
                return row.connectors.map((r) => r.id).join(", ");
            },
            header: "Tomadas",
            size: 50,
            Cell: ({ row }) => row.original.connectors.map((r) => {
                const poder = r.max_electric_power / 1000;
                if (r.standard == "IEC_62196_T2_COMBO") {
                    return (<Tooltip title={<div style={{ textAlign: "center" }}>
                      {poder} kWh
                      <br />
                      C/ {r.format} incluído
                    </div>}>
                  <MdiIcon path={mdiEvPlugCcs2} size={0.7}/>
                </Tooltip>);
                }
                if (r.standard == "IEC_62196_T2") {
                    return (<Tooltip title={<div style={{ textAlign: "center" }}>
                      {poder} kWh
                      <br />
                      C/ {r.format} incluído
                    </div>}>
                  <MdiIcon path={mdiEvPlugType2} size={0.7}/>
                </Tooltip>);
                }
                if (r.standard == "CHADEMO") {
                    return (<Tooltip title={<div style={{ textAlign: "center" }}>
                      {poder} kWh
                      <br />
                      C/ {r.format} incluído
                    </div>}>
                  <MdiIcon path={mdiEvPlugChademo} size={0.7}/>
                </Tooltip>);
                }
            }),
        },
        {
            accessorKey: "capabilities",
            header: "Funcionalidades",
            size: 50,
            Cell: ({ row }) => row.original.capabilities.map((r) => {
                if (r == "RFID_READER") {
                    return (<Tooltip title="Suporte a carregamento por apresentação de cartão">
                  <Chip icon={<MdiIcon path={mdiBroadcast} size={0.7}/>} label="RFID"/>
                </Tooltip>);
                }
                if (r == "REMOTE_START_STOP_CAPABLE") {
                    return (<Tooltip title="Suporte a carregamento via App">
                  <Chip icon={<MdiIcon path={mdiCellphone} size={0.7}/>} label="Mobile" sx={{ mr: 1 }}/>
                </Tooltip>);
                }
            }),
        },
    ], []);
    const goto = (uid) => {
        $state.go("app.reports.locations-sessions", { uid });
    };
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = "Não há registos a serem exibidos";
    return (<Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ mt: 3, ml: 1.8 }}>
          <Typography variant="h6" sx={{ color: "black" }}>
            Localização {/*id*/}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ marginBottom: { xs: 6 } }}>
          <Card sx={{ p: 6, height: "auto" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  ID
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Designação OPC
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Morada
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Cod. Postal
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Tipo de Parque
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Coordenadas
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  {data?.id}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  {data?.party_id}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  {data?.address}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  {data?.postal_code}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  {data?.parking_type}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  {data?.coordinates.latitude}, {data?.coordinates.longitude}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}></Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" sx={{ color: "black", fontWeight: "bold", mb: 1 }}>
            Carregadores Associados
          </Typography>
          <MaterialReactTable columns={columns} data={evses} state={{
            density: "compact",
            isLoading: isLoading,
            showAlertBanner: modalError.show,
        }} enableTopToolbar={false} enablePagination={false} enableBottomToolbar={false} localization={localization} muiCircularProgressProps={{
            color: "secondary",
            thickness: 5,
            size: 55,
        }} muiToolbarAlertBannerProps={modalError.show
            ? {
                color: "error",
                children: (<div style={{
                        display: "flex",
                        gap: 5,
                        alignItems: "center",
                    }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {modalError.title}:
                        </Typography>
                        <Typography>{modalError.body}</Typography>
                      </div>),
            }
            : undefined}/>
        </Grid>
      </Grid>
    </Container>);
}
export default Charger;
