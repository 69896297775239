import React from "react";
import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, } from "material-react-table";
import { Container } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
function OCPICDRList() {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });
    const [columnSort, setColumnSort] = useState([]);
    const [data, setData] = useState({
        data: [],
        meta: { currentPage: 0, itemsPerPage: 20, totalItems: 0, totalPages: 0 },
        links: {},
    });
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    function formatTotalTime(totalTime) {
        const totalHours = parseFloat(totalTime);
        const hours = Math.floor(totalHours);
        const minutes = Math.round((totalHours - hours) * 60);
        return `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}`;
    }
    const initialFetch = async () => {
        if (loading) {
            setLoading(false);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            /*filter.forEach((r) => {
              filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            */
            let sorter = [];
            columnSort.forEach((r) => {
                sorter.push(`${r.id}:${r.desc ? "DESC" : "ASC"}`);
            });
            const response = await axios.get(`https://dev-hgp-ocpi.streamline.pt/hgp/cdrs/table?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}&sortBy=${sorter}`, {
                headers: {
                    Authorization: "Token 2d83dd7c-ad5e-475f-ad7f-c4b51ce06d57",
                },
            });
            // Update URL
            setData(response.data);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, columnSort]);
    let roundToNDigits = (number, p) => (Math.round((number + Number.EPSILON) * Math.pow(10, p)) / Math.pow(10, p)).toLocaleString(undefined, { minimumFractionDigits: 3 });
    const columns = useMemo(() => [
        {
            accessorKey: "cdr_location.id",
            header: "ID Localização",
            size: 50,
        },
        {
            accessorKey: "cdr_token.contract_id",
            header: "ID Contrato",
            size: 50,
        },
        {
            accessorKey: "start_date_time",
            header: "Data de Início do Carregamento",
            size: 50,
            Cell: ({ row }) => moment(row.original.start_date_time).format("DD/MM/YYYY HH:mm"),
        },
        {
            accessorKey: "end_date_time",
            header: "Data de Fim do Carregamento",
            size: 50,
            Cell: ({ row }) => moment(row.original.end_date_time).format("DD/MM/YYYY HH:mm"),
        },
        {
            enableSorting: false,
            muiTableHeadCellProps: {
                align: "right",
            },
            muiTableBodyCellProps: {
                align: "right",
            },
            accessorKey: "total_cost.excl_vat",
            header: "Custo Total Sem IVA (€)",
            size: 50,
            Cell: ({ row }) => (<div style={{ textAlign: "right" }}>
            {roundToNDigits(row.original.total_cost.excl_vat, 3)} €
          </div>),
        },
        {
            enableSorting: false,
            muiTableHeadCellProps: {
                align: "right",
            },
            muiTableBodyCellProps: {
                align: "right",
            },
            accessorKey: "total_time",
            header: "Tempo de Carregamento (hh:mm)",
            size: 100,
            Cell: ({ row }) => (<div style={{ textAlign: "right" }}>
            {formatTotalTime(row.original.total_time)}
          </div>),
        },
        {
            enableSorting: false,
            muiTableHeadCellProps: {
                align: "right",
            },
            muiTableBodyCellProps: {
                align: "right",
            },
            accessorKey: "total_energy",
            header: "Energia Consumida (kWh)",
            size: 50,
            Cell: ({ row }) => (<div>{roundToNDigits(row.original.total_energy, 3)}</div>),
        },
    ], []);
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = "Não há registos a serem exibidos";
    return (<Container maxWidth={false}>
      <h1>Detalhes de carregamentos (OPC)</h1>
      <MaterialReactTable columns={columns} data={data.data} rowCount={data.meta.totalItems} pageCount={data.meta.totalPages} state={{
            density: "compact",
            isLoading: loading,
            sorting: columnSort.map((sort) => ({ id: sort.id, desc: sort.desc })),
            pagination: {
                pageIndex: data?.meta.currentPage > 0 ? data?.meta.currentPage - 1 : 0,
                pageSize: data?.meta.itemsPerPage,
            },
        }} localization={localization} muiCircularProgressProps={{
            color: "secondary",
            thickness: 5,
            size: 55,
        }} enableTopToolbar={false} manualPagination={true} onPaginationChange={setPagination} manualSorting={true} onSortingChange={setColumnSort} layoutMode="grid"/>
    </Container>);
}
export default OCPICDRList;
